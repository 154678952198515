@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
}

.order {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ee204a;
  color: #fff;
  font-weight: 500;
  margin-left: 38%;
}

.order:active {
  background-color: #fff;
  color: #ee204a;
}

.active {
  background-color: #f00f3d;
  color: #fff;
  border-radius: 8px;
  height: 48px;
  font-weight: 500;
}

.animation {
  background-color: #fbdddd;
  animation: mymove 5s infinite;
  position: relative;
}

.style {
  border-bottom-color: #25213b;
  border-bottom-width: 2px;
  padding-bottom: 0.68rem;
}

.Loading {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.Loading::after {
  content: '';
  position: absolute;
  height: 100%;
  left: 0;
  width: 0;
  animation: load infinite 1.5s;
}

@keyframes load {
  0% {
    width: 0%;
    background-color: #ee204a;
  }
  25% {
    width: 25%;
    background-color: #ee204a;
  }
  50% {
    width: 50%;
    background-color: #ee204a;
  }
  75% {
    width: 75%;
    background-color: #ee204a;
  }
  100% {
    width: 100%;
    background-color: #ee204a;
  }
}

.mapContainer {
  width: 100%;
  height: 24.313rem;
  border-radius: 1rem;
}

.mapContainer2 {
  width: 100%;
  height: 28.75rem;
  border-radius: 1rem;
}

.table-container > div {
  padding: 0 !important;
}

thead,
tfoot {
  background-color: #f4f2ff;
  border-top: 0.1rem #d9d5ec solid;
  border-bottom: 0.1rem #d9d5ec solid;
}

.inputType {
  border: 1px solid #a6aab4;
}

.rotate {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scrollBar::-webkit-scrollbar {
  width: 3px;
}

.scrollBar::-webkit-scrollbar-track {
  background-color: #abbed1;
}

.scrollBar::-webkit-scrollbar-thumb {
  background-color: #ea6f06;
}

.markdown > h1 {
  font-size: 20px !important;
  font-weight: 700 !important;
  margin-bottom: 0.5rem !important;
}

.markdown > h2 {
  font-size: 28px !important;
  font-weight: 600 !important;
  margin-top: 2rem !important;
}

.markdown > p {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.markdown > ul {
  margin-left: 1.5rem;
  padding-bottom: 0.8rem;
  font-size: 1.1rem;
}

.markdown > ol {
  margin-left: 1.2rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  font-weight: 600 !important;
  font-size: 1.1rem;
}

@media screen and (min-width: 768px) {
  .markdown > h1 {
    font-size: 26px !important;
    font-weight: 600 !important;
    margin-bottom: 1rem !important;
  }
}

@media screen and (min-width: 768px) {
  .markdown > h2 {
    font-size: 35px !important;
    font-weight: 600 !important;
    margin-top: 2rem !important;
  }
}

@media screen and (min-width: 768px) {
  .markdown > p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 768px) {
  .markdown > ul {
    margin-left: 1.8rem;
    padding-bottom: 1rem;
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 768px) {
  .markdown > ol {
    margin-left: 1.2rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    font-weight: 600 !important;
    font-size: 1.3rem;
  }
}

.calendarElement {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 1.8rem;
  border: 1px solid #ccc;
  z-index: 999;
}
